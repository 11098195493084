import icons from 'assets/icons'
import { useMemo } from 'react'

import { MenuItemAction } from './ContextThumbnail.types'

export const useContent = (
  /** Функция для перевода строк на текущий язык пользователя. */
  t: (key: string) => string,
) => {
  const menuItems = useMemo(
    () => [
      {
        action: MenuItemAction.AddToSplitScreen,
        name: 'splitSmall' as keyof typeof icons,
        text: t('Добавить на разделенный экран'),
      },
      {
        action: MenuItemAction.RotateLabel,
        name: 'rotate90Small' as keyof typeof icons,
        text: `${t('Повернуть этикетку на')} 90°`,
      },
      {
        action: MenuItemAction.RotateAllLabels,
        name: 'rotate90AllSmall' as keyof typeof icons,
        text: `${t('Повернуть все этикетки на')} 90°`,
      },
      { action: MenuItemAction.NewCuts, name: 'newCuts' as keyof typeof icons, text: t('Перерезка') },
      { action: MenuItemAction.Rescan, name: 'rescan' as keyof typeof icons, text: t('Пересканирование') },
      /** Реализация дозаказа Новая окраска перенесено в релиз 3.5.0 (согласовано с Людмила Тюрикова) */
      // { action: MenuItemAction.NewStain, name: 'newStain' as keyof typeof icons, text: t('Новая окраска') },
      {
        action: MenuItemAction.NewSerialCuts,
        name: 'newSerialCutsIcon' as keyof typeof icons,
        text: t('Последовательные срезы'),
      },
      { action: MenuItemAction.Download, name: 'downloadSmall' as keyof typeof icons, text: t('Скачать') },
      { action: MenuItemAction.Delete, name: 'delete' as keyof typeof icons, text: t('Удалить') },
    ],
    [t],
  )

  return menuItems
}
