import { memo, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { IconButtonElement } from './IconButtonElement'
import { IconElement } from './IconElement'
import { TooltipElement } from './TooltipElement'

const ToolbarButton = styled(IconButtonElement)`
  z-index: 13;
`
export const FullScreenButton = memo(() => {
  const [fullScreenActive, setFullscreenActive] = useState(false)
  const { t } = useTranslation()

  const fullScreenToggle = () => {
    if (!fullScreenActive) {
      const body = document.querySelector('body')
      body?.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
  }

  useHotkeys('M', fullScreenToggle, [fullScreenActive])

  const onFullScreenChange = () => {
    const fullscreenElement = document?.fullscreenElement
    setFullscreenActive(!!fullscreenElement)
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullScreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange)
    }
  }, [fullScreenActive])

  useEffect(
    () => () => {
      const fullscreenElement = document?.fullscreenElement
      if (fullscreenElement !== null) document.exitFullscreen()
    },
    [],
  )

  return (
    <TooltipElement title={fullScreenActive ? t('Выход из полноэкранного режима (M)') : t('Полноэкранный режим (M)')}>
      <ToolbarButton
        onClick={fullScreenToggle}
        icon={<IconElement name={fullScreenActive ? 'fullScreenExit' : 'fullScreen'} />}
      />
    </TooltipElement>
  )
})
