import { useTypedSelector } from 'app/redux/lib/selector'
import { setTaskSlideResult } from 'entities/tasks/api/query'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import UserOptions from 'features/user-options/ui/UserOptions'
import { selectTasksViewerUrlTaskId, selectUrlSlideId } from 'pages/viewer/model/viewerPageSlice'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { ButtonElement, IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IMarkupSlideResult } from 'types/IMarkupSlide'
import { IMarkupTask } from 'types/IMarkupTask'

const UserString = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin: 8px;
  border-radius: 5px;
  align-items: center;
`
const HeaderString = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
`
const EstimateBlock = styled.div`
  display: flex;
  justify-content: space-between;
`
const MainBlock = styled.div`
  height: 250px;
  overflow: auto;
  width: 100%;
`
const ShowAll = styled.div`
  color: var(--color-blue);
  cursor: pointer;
`
const StyledIconElement = styled(IconElement)<{ color?: string }>`
  color: ${({ color }) => color};
`
type Props = {
  style?: React.CSSProperties
}

const ValidationUsers = ({ style, ...props }: Props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const urlSlideId = useSelector(selectUrlSlideId)
  const taskData = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const slideResults = queryClient.getQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, urlSlideId])
  const getCurrentResult = useCallback(
    (userId: number | undefined) =>
      userId ? slideResults?.find((itemResult) => itemResult.markupParticipant?.userId === userId) : undefined,
    [slideResults],
  )
  const { mutate: setStatus } = setTaskSlideResult()
  const annotators = useMemo(() => taskData?.participants?.filter((item) => item.access === 'ANNOTATE'), [taskId])
  const currentAnnotationByUser = useTypedSelector((state) => state.annotations.currentAnnotationUsers)

  const annotationByUser = new Set(currentAnnotationByUser)
  const difference = annotators?.length ? [...annotators].filter((item: any) => !annotationByUser.has(item)) : undefined

  const setAnnotationsIsVisibleByName = (annotationName: string) => {
    dispatch(annotationsSlice.actions.setAnnotationsIsVisibleByUser([annotationName]))
  }
  const showAll = () => {
    if (!annotators) return
    const annotatorsName: string[] = []
    for (const annotator of annotators) {
      annotator.user && annotatorsName.push(annotator.user.fullname)
    }
    dispatch(annotationsSlice.actions.setAnnotationsIsVisibleByUser(annotatorsName))
  }
  const { t } = useTranslation()
  const approveHandler = (approveStatus: 'APPROVED' | 'REWORK', currResult: IMarkupSlideResult) => {
    setStatus({
      markupResultId: currResult?.markupResultId,
      markupResultStatus: currResult?.status === 'DONE' ? approveStatus : 'DONE',
      taskId,
      taskSlideId: currResult?.markupSlideId,
      urlSlideId,
    })
  }
  return (
    <>
      <HeaderString>
        <span>{t('Пользователи')}</span>
        <ShowAll onClick={showAll}>{t('Показать всех')}</ShowAll>
      </HeaderString>
      <MainBlock style={style}>
        {annotators?.map((item) => {
          const currResult = getCurrentResult(item.userId)
          const status = currResult?.status || ''
          const annotatorUser = {
            ...item.user,
            fullname: item.user?.fullname || t('Неизвестный пользователь'),
            userId: (item.user?.userId || item.userId) as number,
          }
          return (
            <UserString
              style={{
                background:
                  difference && currentAnnotationByUser?.includes(item.user?.fullname || '')
                    ? 'var(--color-bg-3)'
                    : undefined,
              }}
              key={item.userId}
            >
              <div
                onClick={(e) => {
                  e.preventDefault()
                  item.user?.fullname && setAnnotationsIsVisibleByName(item.user?.fullname)
                }}
              >
                <UserOptions noInteract user={annotatorUser} menuItems={[]} />
              </div>
              {item.access === 'ANNOTATE' &&
                currResult &&
                !['PAUSED'].includes(String(taskData?.status)) &&
                !['WIP'].includes(status) && (
                  <EstimateBlock>
                    <ButtonElement
                      onClick={() => approveHandler('APPROVED', currResult)}
                      style={{
                        height: 24,
                        padding: 0,
                        width: 24,
                      }}
                      icon={
                        <StyledIconElement
                          color={currResult?.status === 'APPROVED' ? 'var(--color-green)' : undefined}
                          name={currResult?.status === 'APPROVED' ? 'likeFill' : 'like'}
                        />
                      }
                      shape="default"
                      size="middle"
                    />
                    <ButtonElement
                      onClick={() => approveHandler('REWORK', currResult)}
                      style={{
                        height: 24,
                        marginLeft: 15,
                        padding: 0,
                        width: 24,
                      }}
                      icon={
                        <StyledIconElement
                          color={currResult?.status === 'REWORK' ? 'var(--color-red)' : undefined}
                          name={currResult?.status === 'REWORK' ? 'dislikeFill' : 'dislike'}
                        />
                      }
                      shape="default"
                      size="middle"
                    />
                  </EstimateBlock>
                )}
            </UserString>
          )
        })}
      </MainBlock>
    </>
  )
}

export default ValidationUsers
