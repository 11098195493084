import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useTypedSelector } from 'app/redux/lib/selector'
import { Integration } from 'entities/lis-integration/types'
import { IWorkspace, SettingsNameEnum } from 'types/IWorkspaces'

type State = {
  currentWorkspace?: IWorkspace
}

const initialState: State = {
  currentWorkspace: undefined,
}

export const workspacesSlice = createSlice({
  initialState,
  name: 'workspacesSlice',
  reducers: {
    setCurrentWorkspace(state, { payload }: PayloadAction<IWorkspace | undefined>) {
      state.currentWorkspace = payload
    },
  },
})

export const useLisMode = () =>
  useTypedSelector(
    (state) =>
      state.workspaces.currentWorkspace?.settings.find((setting) => setting.fieldName === SettingsNameEnum.LisMode)
        ?.value,
  ) as Integration | undefined
export const useResearchRequestDefaultStain = () =>
  useTypedSelector(
    (state) =>
      state.workspaces.currentWorkspace?.settings.find(
        (setting) => setting.fieldName === SettingsNameEnum.researchRequestDefaultStain,
      )?.value,
  ) as Integration | undefined

export const useLisLockSignedMedicalReportAfterSec = () =>
  useTypedSelector(
    (state) =>
      state.workspaces.currentWorkspace?.settings.find(
        (setting) => setting.fieldName === SettingsNameEnum.LisLockSignedMedicalReportAfterSec,
      )?.valueInt,
  )
