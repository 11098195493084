import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseReferencesQuery } from 'features/cases/api/query'
import { viewerPageSlice } from 'pages/viewer'
import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CONFIG_CASE_PANELS_KEY } from 'shared/lib/common/storageKeys'
import { getWorkspaceIdUrl } from 'shared/lib/workspaces'
import { IconElement, Scroll, TitleElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { SlideState } from 'types/ISlide'
import { ISlideGrouped } from 'types/ISlideGrouped'
import { getSlideSummary, isAllSlides } from 'viewer/map/layers/slide/helpers'

import { BlockContainer } from './BlockContatiner'
import { ACTION_SHOW_LABELS, ACTION_SHOW_NAME_SLIDE, ACTION_SHOW_PREVIEW, getCountDefects } from './common/utils'
import { useSlideGrouped } from './hooks'
import { slideBlocksSlice } from './model/BlocksSlice'
import TusFileLoaderContainer from './TusFileLoaderContainer'
import AdaptiveThumbnail from './ui/AdaptiveThumbnail'

type Props = {
  caseId: number
}

const Wrapper = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TitleCount = styled(TitleElement)`
  font-size: 12px !important;
  line-height: 16px !important;
`
const ContainerCount = styled.div`
  display: flex;
  gap: 4px;
  padding: 8px 8px 0 8px;
  flex-wrap: wrap;
`

const CountGroup = styled.div`
  display: flex;
  gap: 4px;
  padding-right: 8px;
`

const StyledIcon = styled(IconElement)<{ isAllSlides?: boolean }>`
  color: ${({ isAllSlides }) => (isAllSlides ? 'var(--color-text-3)' : 'var(--color-red)')};
`

/**
 * Padding левой панели
 *
 * Нужен для расчета ширины превью при включении/выключении этикетки
 * */
export const LEFT_PANEL_PADDING = 8

/** возвращает значение, на основе которого будет сгенерирована заголовок группы слайдов  */
const getKeyBlock = (isMicro: boolean, slideGroup: ISlideGrouped) => {
  if (!isMicro) return 'macroSlidesIds'

  if (slideGroup.container && slideGroup.block) {
    return `${slideGroup.container}-${slideGroup.block}`
  }

  if (slideGroup.container) {
    return `${slideGroup.container}`
  }

  if (slideGroup.block) {
    return `${slideGroup.block}`
  }

  return ''
}

const AdaptiveThumbnailListContainer = memo(({ caseId }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const workspaceId =
    useTypedSelector((state) => state.workspaces.currentWorkspace)?.workspaceId ||
    getWorkspaceIdUrl(window.location.href)
  const { data: caseReferencesSlides } = useCaseReferencesQuery({
    caseId: caseId,
    workspaceId: Number(workspaceId),
  })
  const { filesList, slideGrouped, slides } = useSlideGrouped(caseId)

  useEffect(() => {
    const configString = localStorage.getItem(CONFIG_CASE_PANELS_KEY)
    if (configString) {
      const configParse = JSON.parse(configString)
      const configCasePanels = {
        [ACTION_SHOW_LABELS]: configParse[ACTION_SHOW_LABELS],
        [ACTION_SHOW_NAME_SLIDE]: configParse[ACTION_SHOW_NAME_SLIDE],
        [ACTION_SHOW_PREVIEW]: configParse[ACTION_SHOW_PREVIEW],
      }
      dispatch(viewerPageSlice.actions.setConfigPanel(configCasePanels))
    }
  }, [])

  useEffect(
    () => () => {
      dispatch(slideBlocksSlice.actions.setInitialState())
    },
    [],
  )

  const slideSummary = getSlideSummary(slides, caseReferencesSlides)
  const defectsCount = getCountDefects(slides)

  const areAllSlidesEqual = isAllSlides(slides, caseReferencesSlides)

  const unviewedSlides = slides.reduce(
    (count, slide) => (slide.groupType === 'MICRO' && slide.state === 'AVAILABLE' && !slide.viewed ? count + 1 : count),
    0,
  )

  return (
    <>
      <ContainerCount>
        <TooltipElement placement="topLeft" title={t('Загруженные микропрепараты')}>
          <CountGroup>
            <StyledIcon isAllSlides={areAllSlidesEqual} size={'md'} name={'imagesSmall'} />
            {!!slideSummary && <TitleCount>{slideSummary}</TitleCount>}
          </CountGroup>
        </TooltipElement>
        {!!unviewedSlides && (
          <TooltipElement placement="topLeft" title={<p>{t('Непросмотренные микропрепараты')}</p>}>
            <CountGroup>
              <IconElement fill="var(--color-blue)" size="md" name="newSlideSmall" />
              <TitleCount>{unviewedSlides}</TitleCount>
            </CountGroup>
          </TooltipElement>
        )}
        {!!defectsCount && (
          <CountGroup>
            <StyledIcon size={'md'} name={'defectSlideSmall'} />
            <TitleCount>{defectsCount}</TitleCount>
          </CountGroup>
        )}
      </ContainerCount>
      <Divider style={{ marginBottom: 4 }} />
      <Scroll style={{ padding: LEFT_PANEL_PADDING }}>
        {slideGrouped?.map((item, index) => {
          /** slideGrouped - Массив объектов слайдов, сгруппированных по типу. */
          const isMicro = item.type === 'MICRO'
          const key = getKeyBlock(isMicro, item)
          const blocks = item.slides
          /** failedBlock - Флаг, указывающий на наличие слайда с type === null и state === "FAILED" */
          const failedBlock = item.type === null && item.slides.some((slide) => slide.state === SlideState.FAILED)
          /** emptyBlocks - Флаг, указывающий, что контейнер и блок не определены для типа MICRO. */
          const emptyBlocks = !!(isMicro && !item.container && !item.block)
          /** macroSlidesIds – Флаг контейнера слайдов в зависимости от их типа (MICRO или MACRO). */
          const macroSlidesIds = !isMicro
          const INDEX_MACRO = 10000

          return (
            <BlockContainer
              key={index}
              i={isMicro ? index : macroSlidesIds ? INDEX_MACRO : 9999}
              blocks={blocks}
              caseId={caseId}
              typedKey={key}
              emptyBlocks={emptyBlocks}
              failedBlock={failedBlock}
              macroSlidesIds={macroSlidesIds}
            />
          )
        })}

        {filesList.length > 0 && (
          <Wrapper>
            {filesList.map((fileData) => (
              <AdaptiveThumbnail activeCursor="A" key={fileData}>
                <TusFileLoaderContainer tusFileId={fileData} key={fileData} />
              </AdaptiveThumbnail>
            ))}
          </Wrapper>
        )}
      </Scroll>
    </>
  )
})

export default AdaptiveThumbnailListContainer
