import { useMutation, useQuery, useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'

import additionalOrderService from './service'
import { NewSerialCutsPayload } from './type'

export const useAdditionalServiceQuery = (caseId: number | string, isMarkup: boolean) => {
  const { isResearchRequestEnabled } = useSettingsAndUserRoles()

  return useQuery([QUERY_TYPE.ADDITIONAL_SERVICE, caseId], () => additionalOrderService.getAdditionalService(caseId), {
    enabled: isResearchRequestEnabled && !isMarkup,
  })
}

export const useAdditionalServiceMutation = (caseId: number) => {
  const queryClient = useQueryClient()

  return useMutation<unknown, Error, NewSerialCutsPayload>(
    async (data: NewSerialCutsPayload) => await additionalOrderService.postAdditionalService(caseId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_TYPE.ADDITIONAL_SERVICE])
      },
    },
  )
}
