import ICase, { ICaseRelation } from 'types/ICase'
import ISlide from 'types/ISlide'

import ivanova_mamo from './images/ivanova_mamo.png'

const MAMO_CASE_IVANOVA: ICase = {
  caseId: 999999901,
  casePathologicalInfo: {
    casePathologicalInfoId: 1,
    diagnosticProcedureType: { id: 1, name: 'CT', shortName: '2' },
    sites: [
      {
        container: null,
        site: {
          id: 113,
          name: 'МОЛОЧНАЯ ЖЕЛЕЗА',
          shortName: '50',
        },
      },
    ],
  },
  caseRadiologicalInfo: {
    description: '',
    site: '',
  },
  caseType: 'PATHOLOGICAL',
  commentInfo: {
    lastReadCommentId: 0,
    unreadCount: 0,
  },
  createdAt: '2021-01-26T09:21:39.791',
  deleted: false,
  deletedAt: null,
  description:
    'МРТ 29.12.2020 - Состояние после секторальной билатеральной резекции по поводу кистозного ФАМ от 1996г. ',
  favoriteLists: [],
  materialTakeoutDate: '2021-01-26T12:00:00',
  modalities: [],
  name: 'M00134567',
  ohivPath: '/ohiv/viewer/1.2.840.113619.2.255.2079247158114.291210126133017.20000',
  patient: {
    birthDate: '1976-05-27',
    fullname: 'Иванова Марина Владимировна',
    sex: 'FEMALE',
  },
  relation: ICaseRelation.OWNER,
  siteSpecificMetadata: {
    gleasonGroupCalculated: 5,
    type: 'PROSTATE_CANCER_METADATA',
  },
  slideDefect: null,
  slideStats: {
    referencesCount: 0,
    slidesCount: 0,
  },
  source: 'PLATFORM',
  status: 'OPEN',
  updatedAt: '2021-01-26T10:42:39.736',
  user: {
    email: 'test+spectre@onecell.ai',
    fullname: 'Кирилл Кошелев',
    userId: 51,
  },
}

export const MAMO_CASE_IVANOVA_SLIDES: ISlide[] = [
  {
    caseId: 999999901,
    deleted: false,
    deletedAt: null,
    groupType: 'MICRO',
    iiif2Url:
      'https://cantaloupe-hdd.test.onecell.ru/iiif/2/ca85bfa13b89eef062f59f0867ee5657%2F4329%2F216%2Ftest-stage-slides%2F61%2F2%2Fcase%2F04a93be6-1695-4faf-a95c-b3a5a318bd30.vips.tiff/info.json',
    searchModels: [],
    slideDefect: null,
    slideId: 99999991,
    slideMetadata: {
      caseSpecificMetadata: {
        gleasonGroup: 5,
        gleasonPatternPrimary: 5,
        gleasonPatternSecondary: 4,
        pattern2Area: {
          GLEASON4: 155.0,
          GLEASON5: 682.0,
          OTHER: 1.988905e7,
        },
        result: {
          GLEASON4: {
            classId: 3,
            relativeValue: 0.1852,
          },
          GLEASON5: {
            classId: 4,
            relativeValue: 0.8148,
          },
        },
        type: 'PROSTATE_CANCER_METADATA',
      },
      commonMetadata: {
        caption: 'OHIV-1.3.6.1.4.1.14519-1',
        mppX: 0.2498,
        objectivePower: 40,
        segmentationStatus: 'AVAILABLE',
      },
    },
    state: 'AVAILABLE',
    thumbnails: {
      large: ivanova_mamo,
      medium: ivanova_mamo,
      small: ivanova_mamo,
    },
    viewed: true,
  },
]

export default MAMO_CASE_IVANOVA
