import { useTypedSelector } from 'app/redux/lib/selector'
import { ModalWrapper, useAdditionalServiceMutation } from 'features/additional-order'
import { viewerPageSlice } from 'pages/viewer'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useViewerDispatch } from 'viewer/container'

import { INewStainProps } from './NewStain.types'

export const NewStain = ({ caseId, viewerId }: INewStainProps) => {
  const [valueComment, setValueComment] = useState('')

  const refModal = useRef<any>()
  const { t } = useTranslation()
  const { isNewStain } = useTypedSelector((state) => state.viewerPage)
  const viewerDispatch = useViewerDispatch(viewerId)

  const { error, isError, isLoading, isSuccess } = useAdditionalServiceMutation(caseId)

  const onClose = () => {
    viewerDispatch(viewerPageSlice.actions.setNewStainModal(false))
  }

  useEffect(() => {
    isNewStain ? refModal.current.open() : refModal.current.close()
  }, [isNewStain])

  useEffect(() => {
    if (isSuccess) {
      onClose()
    }
  }, [isSuccess])

  return (
    <ModalWrapper
      title={t('Новая окраска')}
      refModal={refModal}
      error={error}
      handleSubmit={() => {}}
      isDisabled={isLoading}
      handleComment={(e) => setValueComment(e.target.value)}
      valueComment={valueComment}
      isError={isError}
      onClose={onClose}
    >
      {t('Новая окраска')}
    </ModalWrapper>
  )
}
