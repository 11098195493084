import icons from 'assets/icons'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

type SlideMarkerIconProps = {
  /**  Определяет, виден ли маркер. */
  isVisible: boolean
  /** Текст для подсказки (используется для локализации). */
  tooltip: string
  /** Цвет иконки. */
  color: string
  /** Имя отображаемой иконки. */
  iconName: keyof typeof icons
}

const SlideMarkerIcon: React.FC<SlideMarkerIconProps> = ({ color, iconName, isVisible, tooltip }) => {
  const { t } = useTranslation()

  if (!isVisible) return null

  const icon = (
    <div style={{ height: 16 }}>
      <IconElement fill={color} size="md" name={iconName} />
    </div>
  )

  return (
    <TooltipElement placement="right" title={t(tooltip)}>
      {icon}
    </TooltipElement>
  )
}

type SlideMarkersProps = {
  /** Определяет видимость иконки "дефект". */
  isDefectSlide: boolean
  /** Определяет видимость иконки "дефект ложноположительный". */
  isDefectFalsePositive: boolean
  /**  Определяет видимость иконки "не просмотрено". */
  isNewSlideIconVisible: boolean
}

export const SlideMarkers: React.FC<SlideMarkersProps> = memo(
  ({ isDefectFalsePositive, isDefectSlide, isNewSlideIconVisible }) => (
    <MarkerContainer>
      <SlideMarkerIcon
        isVisible={isDefectSlide}
        tooltip="Микропрепараты с дефектами"
        color="var(--color-red)"
        iconName="defectSlideSmall"
      />
      <SlideMarkerIcon
        isVisible={isDefectFalsePositive}
        color="var(--color-text-3)"
        tooltip="Ошибочная детекция дефектов"
        iconName="falseDefectSlideSmall"
      />
      <SlideMarkerIcon
        isVisible={isNewSlideIconVisible}
        tooltip="Не просмотрено"
        color="var(--color-blue)"
        iconName="newSlideSmall"
      />
    </MarkerContainer>
  ),
)

const MarkerContainer = styled.div`
  cursor: pointer;
  display: flex;
  gap: 2px;
  position: absolute;
  right: -6px;
  top: -6px;
  background: var(--color-bg-2);
  padding: 4px;
  border-radius: 12px 0 12px 12px;
  z-index: 10;
`
