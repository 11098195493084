import React from 'react'
import { IconElement } from 'shared/ui/kit'

const NewSlideIcon = () => (
  <div
    style={{
      background: 'var(--color-bg-2)',
      borderRadius: '12px 0px 12px 12px',
      color: 'var(--color-blue)',
      display: 'flex',
      gap: '2px',
      padding: 4,
      position: 'absolute',
      right: -6,
      top: -6,
    }}
  >
    <IconElement name="newSlideSmall" size={'md'} />
  </div>
)

export default NewSlideIcon
