import { useThemeContext } from 'app/styled/ThemeProvider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateForDisplay } from 'shared/lib/date'
import { TextElement } from 'shared/ui/kit'

import {
  Block,
  Button,
  Dot,
  Item,
  Mark,
  ShowMoreButton,
  Span,
  Status,
  StyledPanel,
  Title,
  TitleSpan,
  TitleWrapper,
} from './AdditionalOrderInfo.styles'
import { AdditionalOrderInfoProps } from './AdditionalOrderInfo.types'
import { ADDITIONAL_ORDER, STATUSES } from './constants'

const COMMENT_PREVIEW_LENGTH = 75

export function AdditionalOrderInfo({ data, handleClick, isVisiblePanel }: AdditionalOrderInfoProps) {
  const { t } = useTranslation()
  const [expandedItems, setExpandedItems] = useState<{ [key: number]: boolean }>({})
  const colorTheme = useThemeContext()

  const toggleExpanded = (id: number) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  return (
    <>
      <Button isFocus={isVisiblePanel} onClick={handleClick}>
        {t('Дозаказы')} <Mark>{data.length}</Mark>
      </Button>

      {isVisiblePanel && (
        <StyledPanel>
          {data.map((item) => {
            const isExpanded = expandedItems[item.researchRequestId]

            return (
              <Item key={item.researchRequestId}>
                <Block>
                  <TitleWrapper>
                    <Title>
                      <TitleSpan>{t(ADDITIONAL_ORDER[item.type])}</TitleSpan>
                      <Span color="grey">{formatDateForDisplay(item.createdAt)}</Span>
                    </Title>
                    <Status theme={colorTheme.theme} status={item.status}>
                      {t(STATUSES[item.status])}
                    </Status>
                  </TitleWrapper>
                  <TextElement ellipsis style={{ color: 'var(--color-text-3)' }}>
                    {item.createdBy.fullname}
                  </TextElement>
                </Block>

                <Block>
                  {item.slides.map((slide) => (
                    <Span key={slide.blockExternalId} color="white">
                      {t('Блок')} {slide.blockExternalId} <Dot /> {slide.stain?.shortName}
                    </Span>
                  ))}
                </Block>

                {item.preparationComment && (
                  <Block>
                    <Span color="grey">{t('Комментарий для лаборанта')}</Span>
                    <Span color="white">
                      {isExpanded
                        ? item.preparationComment
                        : `${item.preparationComment.slice(0, COMMENT_PREVIEW_LENGTH)}`}
                      {item.preparationComment.length > COMMENT_PREVIEW_LENGTH && (
                        <ShowMoreButton onClick={() => toggleExpanded(item.researchRequestId)}>
                          {t(isExpanded ? 'Скрыть' : 'Показать все')}
                        </ShowMoreButton>
                      )}
                    </Span>
                  </Block>
                )}
              </Item>
            )
          })}
        </StyledPanel>
      )}
    </>
  )
}
