import { useTypedSelector } from 'app/redux/lib/selector'
import stubLabel from 'assets/thumbnails/stub-label.png'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { viewerPageSlice } from 'pages/viewer'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { VIEWERS_SET } from 'pages/viewer/model/viewerPageSlice'
import { updateConfigLocalStorage } from 'pages/viewer/ui/common/helpers'
import React, { useCallback, useContext, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { isGuestHandler } from 'shared/lib/local-storage'
import { MapContext } from 'shared/lib/map'
import { getSlideBarcode, getSlideGroupType, getSlideName } from 'shared/lib/metadata'
import { getBooleanOrDefaultAccess } from 'shared/lib/workspaces'
import { ImageElement, TextElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import ISlide, { SlideState } from 'types/ISlide'
import { ISlideGrouped } from 'types/ISlideGrouped'
import { SettingsNameEnum } from 'types/IWorkspaces'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, VIEWER_COLORS } from 'viewer/container'
import { RIGHT_ANGLE, RIGHT_ANGLE_X_3, STRAIGHT_ANGLE } from 'viewer/tools/ui/lib/constans'

import { ACTION_SHOW_LABELS, ACTION_SHOW_NAME_SLIDE, ACTION_SHOW_PREVIEW } from './common/utils'
import AdaptiveThumbnail from './ui/AdaptiveThumbnail'
import ThumbnailSpin from './ui/ThumbnailSpin'

const SlideInfo = styled.div<{ shouldShowLabels: boolean }>`
  width: ${({ shouldShowLabels }) => (shouldShowLabels ? '50%' : '100%')};
  height: 97.3%;
  position: absolute;
  left: 0.65%;
  top: 1.3%;
  z-index: 1;
  justify-content: space-between;
  display: flex;
  gap: 4px;
  flex-direction: column;
  border-radius: 3px;
`
const Title = styled.div`
  padding: 1px 4px;
  color: black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ContentContainer = styled.div`
  background: rgba(255, 255, 255, 0.7);
  width: fit-content;
`

const StyleTitleItem = styled(TitleElement)`
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 12px !important;
`

const TitleListContainer = styled.div<{ selectedByViewer: TViewerId }>`
  background-color: ${({ selectedByViewer }) => selectedByViewer && VIEWER_COLORS[selectedByViewer]};
  height: 16px;
  width: 100%;
  border-radius: 3px;
  padding: 2px 4px;
  border: 2px solid var(--colors-purple);
  margin-bottom: 8px;
  &:hover {
    background: ${({ selectedByViewer }) => !selectedByViewer && 'var(--color-border-1)'};
  }
`

const MENU_HEIGHT = 155

type Props = {
  /** Данные о слайде */
  slide: ISlide
  /** Id кейса */
  caseId: number
  /** Отображение ui опции сплт-вью */
  splitButton?: boolean
  /** Обработка клика на миниатюру слайда */
  onClick?: (caseId: number, slideId: number) => void
}

const AdaptiveThumbnailContainer = ({ caseId, onClick, slide }: Props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const slideId = slide.slideId
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const slides = queryClient.getQueryData<ISlideGrouped[]>([QUERY_TYPE.SLIDE_GROUPED, caseId])
  const storedWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const authorities = useTypedSelector((state) => state.user.authorities)
  const { configPanels, label } = useTypedSelector((state) => state.viewerPage)
  const labelUrl = label[slideId]?.labelUrl || ''
  const labelRotate = label[slideId]?.rotate
  const { activeViewerId, openViewerIds } = useOpenViewers()
  const { changeViewerSlide, viewingState } = useViewerPageProvided()
  const { viewerId } = useContext(MapContext)
  const { slideId: currentSlideId } = useViewerIdSlideState(viewerId)
  const viewerDispatch = useViewerDispatch(viewerId)
  const isShared = caseRecord?.relation === 'SHARED'
  const isReadOnly = caseRecord?.permissionLevel === 'READ_ONLY' && isShared
  const isGuest = isGuestHandler(authorities)

  const shouldShowPreview = configPanels[ACTION_SHOW_PREVIEW]
  const shouldShowLabels = configPanels[ACTION_SHOW_LABELS]
  const shouldShowNameSlide = configPanels[ACTION_SHOW_NAME_SLIDE]
  const shouldDisplayLabelAndPreview = shouldShowLabels && shouldShowPreview && !isReadOnly && !isGuest
  const isMacroGroupType = getSlideGroupType(slide) === 'MACRO'
  const showSlideBarcodeInsteadOfCaption = getBooleanOrDefaultAccess(
    SettingsNameEnum.ShowSlideBarcodeInsteadOfCaption,
    false,
    storedWorkspace,
  )

  const slidesCurrent = slides?.flatMap((item) => item.slides)
  const { unsubscribeFromUser } = useUserStatusContext()

  const namePreview = isMacroGroupType
    ? getSlideName(slide)
    : showSlideBarcodeInsteadOfCaption
    ? getSlideBarcode(slide)
    : getSlideName(slide)

  const targetStainCode = slide?.stain?.shortName

  const getViewerIdBySlideId = useCallback(
    (slideId: number) => {
      let id: TViewerId | string = ''
      openViewerIds.forEach((vid) => {
        if (viewingState[vid]?.slide.slideId === slideId) id = vid
      })
      return (id as TViewerId) || undefined
    },
    [openViewerIds, viewingState],
  )
  const selectedByViewer = getViewerIdBySlideId(slideId)

  const applyConfigAction = (action: string) => {
    viewerDispatch(viewerPageSlice.actions.setToggleConfigPanel(action))
    updateConfigLocalStorage(action)
  }

  useEffect(() => {
    if (!shouldShowPreview && !shouldShowNameSlide && isReadOnly) {
      applyConfigAction(ACTION_SHOW_PREVIEW)
      applyConfigAction(ACTION_SHOW_NAME_SLIDE)
    }
  }, [])

  const onThumbnailClick = () => {
    unsubscribeFromUser()
    currentSlideId !== slideId &&
      changeViewerSlide(activeViewerId, {
        caseId,
        slideGroupType: slide?.groupType || 'MICRO',
        slideId,
        source: 'PLATFORM',
        viewerMode: 'DEFAULT',
      })
    onClick && onClick(caseId, slideId)
  }

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault && e.preventDefault()
    if (slide?.state === SlideState.FAILED) return
    const windowHeight = window.innerHeight
    const y = e.clientY > windowHeight - MENU_HEIGHT ? e.clientY - MENU_HEIGHT : e.clientY
    dispatch(viewerPageSlice.actions.setThumbnailMenuPosition([e.clientX, y]))
    dispatch(viewerPageSlice.actions.setSlideThumbnailHover(slide))
  }

  const borderRadius =
    labelRotate === RIGHT_ANGLE
      ? '1.5px 1.5px 0 0'
      : labelRotate === STRAIGHT_ANGLE
      ? '1.5px 0 0 1.5px'
      : labelRotate === RIGHT_ANGLE_X_3
      ? '0 0 1.5px 1.5px'
      : '0 1.5px 1.5px 0'

  const isSecondViewerSelected = selectedByViewer === VIEWERS_SET[1]
  const isViewerSelected = selectedByViewer && !isSecondViewerSelected
  const textColor = isSecondViewerSelected
    ? 'var(--color-black)'
    : isViewerSelected
    ? 'var(--color-white)'
    : 'var(--color-text-1)'
  const renderThumbnailContent = () => (
    <AdaptiveThumbnail
      slide={slide}
      slideId={slideId}
      shouldDisplayLabelAndPreview={shouldDisplayLabelAndPreview}
      activeCursor={activeViewerId}
      selectedByViewer={selectedByViewer}
      handleContextMenu={handleContextMenu}
      onClick={onThumbnailClick}
      slides={slidesCurrent}
      content={
        (slide?.stain?.shortName || slide?.slideMetadata?.commonMetadata?.objectivePower) &&
        shouldShowPreview && (
          <SlideInfo shouldShowLabels={shouldShowLabels}>
            {slide?.slideMetadata?.commonMetadata?.objectivePower && (
              <ContentContainer style={{ borderRadius: '3px 0 0 0' }}>
                <Title style={{ fontSize: 10, lineHeight: '12px' }}>
                  {slide?.slideMetadata?.commonMetadata?.objectivePower + '×'}
                </Title>
              </ContentContainer>
            )}
            {slide?.stain?.shortName && (
              <ContentContainer style={{ borderRadius: '0 0 0 3px', bottom: 0, maxWidth: '85%', position: 'inherit' }}>
                <Title style={{ fontSize: '100%', lineHeight: '18px' }}>{targetStainCode}</Title>
              </ContentContainer>
            )}
          </SlideInfo>
        )
      }
      footer={
        shouldShowNameSlide && (
          <TextElement
            style={{
              color: textColor,
              fontSize: '11px',
              lineHeight: '12px',
              padding: '2px 4px',
            }}
            ellipsis
          >
            {namePreview}
          </TextElement>
        )
      }
    >
      {shouldShowPreview && (
        <>
          <ImageElement
            style={{
              background: 'white',
              borderRadius: shouldShowLabels ? '1.5px 0 0 1.5px' : '1.5px',
              width: isMacroGroupType || isReadOnly || !shouldShowLabels || isGuest ? '100%' : '50%',
            }}
            src={slide?.thumbnails.medium}
          />
          {shouldShowLabels && !isMacroGroupType && !isReadOnly && !isGuest && (
            <ImageElement
              style={{
                background: 'white',
                borderRadius: labelUrl ? borderRadius : '0 1.5px 1.5px 0',
                transform: labelUrl ? `rotateZ(${labelRotate || 0}deg)` : undefined,
                width: '50%',
              }}
              src={labelUrl || stubLabel}
            />
          )}
        </>
      )}

      {isMacroGroupType && !shouldShowPreview && (
        <ImageElement
          style={{
            background: 'white',
            borderRadius: shouldShowLabels ? '5px 0 0 5px' : '3px',
            width: isMacroGroupType || isReadOnly || !shouldShowLabels ? '100%' : '50%',
          }}
          src={slide?.thumbnails.medium}
        />
      )}
      {!shouldShowPreview && shouldShowLabels && !isMacroGroupType && !isReadOnly && !isGuest && (
        <ImageElement
          style={{
            background: 'none',
            borderRadius: shouldShowPreview ? '3px 0 0 3px' : '3px',
            transform: labelUrl ? `rotateZ(${labelRotate || 0}deg)` : undefined,
            width: '100%',
          }}
          src={labelUrl || stubLabel}
        />
      )}
    </AdaptiveThumbnail>
  )

  if (slide) {
    if (slide.state === 'PROCESSING') {
      return (
        <AdaptiveThumbnail activeCursor={activeViewerId}>
          <ThumbnailSpin />
        </AdaptiveThumbnail>
      )
    }
    return shouldShowPreview || (shouldShowLabels && !isReadOnly && !isGuest) || isMacroGroupType ? (
      renderThumbnailContent()
    ) : (
      <TitleListContainer
        onContextMenu={handleContextMenu}
        selectedByViewer={selectedByViewer}
        onClick={onThumbnailClick}
      >
        <StyleTitleItem style={{ color: textColor }} ellipsis>
          {targetStainCode ? `${targetStainCode}: ` : undefined}
          {namePreview}
        </StyleTitleItem>
      </TitleListContainer>
    )
  }
  return null
}

export default AdaptiveThumbnailContainer
