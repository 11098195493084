import ISlide from 'types/ISlide'

// Проверяет, что селекченные слайды в одном блоке
export function checkSameGroup(slides?: ISlide[], selectedSlides?: Array<number>): boolean {
  const selectedSlideObjects = slides?.filter((slide) => selectedSlides?.includes(slide.slideId))
  const firstGroupType = selectedSlideObjects?.[0]?.groupType
  const allSameGroupType = selectedSlideObjects?.every((slide) => slide.groupType === firstGroupType)

  if (!allSameGroupType || !selectedSlides?.length) {
    return false // Слайды не в одной группе, если у них разные типы групп
  }

  // Если все слайды имеют groupType "MACRO", то они в одной группе
  if (firstGroupType === 'MACRO') {
    return true
  }

  // Для группы "MICRO" проверяем совпадение block и container
  const firstBlock = selectedSlideObjects?.[0]?.block
  const firstContainer = selectedSlideObjects?.[0]?.container

  const allSameBlockAndContainer = selectedSlideObjects?.every(
    (slide) => slide.block === firstBlock && slide.container === firstContainer,
  )

  return !!allSameBlockAndContainer
}
