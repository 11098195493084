import { Select } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NON_CONTEXT_ID } from 'shared/lib/map/ui/MapProvider'
import { SelectElement, SelectElementProps, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { DictionaryItem } from 'types/IDictionary'

const { OptGroup, Option } = Select

const SiteLabel = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 16px;
  width: 100%;
  white-space: normal;
  text-overflow: initial;
  display: flex;
  justify-content: flex-start;
  padding-top: 2px;
`

type Props = {
  stains: DictionaryItem[]
  stain?: DictionaryItem
  isDisabled?: boolean
} & Omit<SelectElementProps, 'options' & 'groups'>

const StainSelectElement = ({ isDisabled, stain, stains, ...rest }: Props) => {
  const [searchValue, onSearch] = useState('')
  const { t } = useTranslation()
  return (
    <SelectElement
      {...rest}
      disabled={isDisabled}
      onSearch={onSearch}
      searchValue={searchValue}
      showSearch
      filterOption={false}
      onSelect={() => onSearch('')}
      id={NON_CONTEXT_ID}
    >
      {searchValue ? (
        stains.map((stain) => createSearchOption(stain, searchValue))
      ) : (
        <>
          <OptGroup id={NON_CONTEXT_ID} label={t('Популярные окраски')}>
            {stains.map((stain) => createPopularOption(stain))}
          </OptGroup>
          <OptGroup id={NON_CONTEXT_ID} label={t('Остальные')}>
            {stains.map((stain) => creatOtherOption(stain))}
          </OptGroup>
        </>
      )}
    </SelectElement>
  )
}

const createSearchOption = (stain: DictionaryItem, searchValue: string) => {
  if ((searchValue && !findQueryInSite(searchValue, stain)) || !stain.id || !stain.shortName) {
    return null
  }
  return searchValue ? (
    <Option value={stain.id} key={stain.shortName}>
      {createOptionLabel(stain)}
    </Option>
  ) : null
}

const createPopularOption = (stain: DictionaryItem) =>
  stain.visible ? (
    <Option value={stain.id} key={stain.id + stain.shortName}>
      {createOptionLabel(stain)}
    </Option>
  ) : null

const creatOtherOption = (stain: DictionaryItem) =>
  !stain.visible ? (
    <Option value={stain.id} key={stain.shortName}>
      {createOptionLabel(stain)}
    </Option>
  ) : null

const createOptionLabel = (stain: DictionaryItem) => (
  <SiteLabel>
    <TitleElement ellipsis level={4} style={{ width: '100%' }}>
      {stain.name}
    </TitleElement>
  </SiteLabel>
)

const findQueryInSite = (query: string, site: DictionaryItem) =>
  site.name?.toLowerCase().includes(query.toString().toLowerCase()) ||
  site.shortName?.toLowerCase().includes(query.toString().toLowerCase())

export default StainSelectElement
