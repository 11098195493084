import { del, get, post } from 'shared/api'
import { DictionaryItem } from 'types/IDictionary'
import ISlide, { ISlideDownloadItem, ISlideDTO } from 'types/ISlide'
import { ICaseSpecificMetadata } from 'types/ISlideMetadata'
import IUploadInfo from 'types/ISlideUploadInfo'
import ISlideViewed from 'types/ISlideViewed'
import ISource from 'types/ISource'

type UpdateRotateLabelsProps = {
  /** id кейса */
  caseId: string | number
  /** Массив редактируемых этикеток */
  data: {
    data: { labelRotation: number; slideId: number }[]
  }
}
const getDataForUpload = async (caseId: number) => {
  try {
    const response = await post<IUploadInfo>({
      ignoreCatch: true,
      url: `/case/${caseId}/slide`,
    })
    return response
  } catch (e) {
    throw e
  }
}

const fetchSingleSlide = async (caseId: string | number, slideId: string | number, source: ISource) =>
  get<ISlide>({
    config: {
      params: {
        source,
      },
    },
    url: `/case/${caseId}/slide/${slideId}`,
  })

const updateRotateLabels = ({ caseId, data }: UpdateRotateLabelsProps) =>
  post<ISlideDTO>({
    data,
    url: `/case/${caseId}/slide/rotate-labels`,
  })

const updateSingleSlide = async (
  caseId: string | number,
  slideId: string | number,
  data: {
    block?: string | null
    container?: string | null
    //TODO: check this
    morphologies?: DictionaryItem[]
    caseSpecificMetadata?: ICaseSpecificMetadata
    labelRotation?: number
  },
) =>
  post<ISlideDTO>({
    data,
    url: `/case/${caseId}/slide/${slideId}`,
  })

const deleteSingleSlide = async (caseId: string | number, slideId: string | number) =>
  del({
    url: `/case/${caseId}/slide/${slideId}`,
  })

const restoreSingleSlide = async (caseId: string | number, slideId: string | number) =>
  post({
    url: `/case/${caseId}/slide/${slideId}/restore`,
  })
const findAllByMetaHash = async (caseId: string | number, metaHashes: string[]) =>
  post<Record<string, ISlideDTO>>({
    data: metaHashes,
    url: `/case/${caseId}/slide/search`,
  })

const getLabelImage = async (caseId: string | number, slideId: string | number, source: ISource = 'PLATFORM') =>
  get({
    config: {
      params: {
        source,
      },
    },
    url: `/case/${caseId}/slide/${slideId}/label`,
  })

const getIiifConfig = async (url?: string) => {
  if (!url) return
  try {
    const response = await fetch(url)
    return response?.ok ? response.json() : null
  } catch (e) {
    return null
  }
}

const getSlideViewed = async (caseId: string | number, slideId: string | number) =>
  get<ISlideViewed>({
    url: `/case/${caseId}/slide/${slideId}/viewed-tracking`,
  })

const updateSlideViewedTracking = async (caseId: string | number, slideId: string | number, geojson: any) =>
  post({
    data: geojson,
    url: `/case/${caseId}/slide/${slideId}/viewed-tracking`,
  })

/**
 * Отправляет запрос для обновления информации о просмотре слайда.
 *
 * @param {number} workspaceId - Идентификатор рабочего пространства.
 * @param {number} caseId - Идентификатор кейса.
 * @param {number} slideId - Идентификатор слайда.
 * @returns {Promise} - Промис, представляющий результат выполнения POST-запроса.
 */
const updateSlideViewed = (workspaceId: number, caseId: number, slideId: number): Promise<unknown> =>
  post({
    url: `/workspace/${workspaceId}/case/${caseId}/slide/${slideId}/viewed`,
  })

const downloadSlide = async (workspaceId: string, caseId: number, groupIds: number[]) =>
  post<ISlideDownloadItem[]>({
    data: { slides: [...groupIds] },
    url: `/workspace/${workspaceId}/case/${caseId}/slide/download`,
  })

const slideService = {
  deleteSingleSlide,
  downloadSlide,
  fetchSingleSlide,
  findAllByMetaHash,
  getDataForUpload,
  getIiifConfig,
  getLabelImage,
  getSlideViewed,
  restoreSingleSlide,
  updateRotateLabels,
  updateSingleSlide,
  updateSlideViewed,
  updateSlideViewedTracking,
}

export default slideService
