import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import styled from 'styled-components/macro'

export const SlideContainer = styled.div`
  margin-bottom: 8px;
  max-height: 300px;
  overflow-y: auto;
`

export const Dot = styled.span`
  &:before {
    content: '•';
  }
`

export const SlideText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 18px;
  font-variant-numeric: slashed-zero;
  color: var(--color-text-1);
  padding: 4px 8px;
  margin-bottom: 4px;

  &:hover {
    background-color: var(--color-bg-3);
  }
`

export const SlideTextInner = styled.div`
  display: flex;
  column-gap: 8px;
`

export const SlideTextDropdown = styled(SlideText)`
  cursor: pointer;
`

export const ButtonAdd = styled.button`
  margin-bottom: 5px;
  border-radius: 5px;
  font-size: 12px;
  color: var(--color-text-1);
  background-color: var(--color-bg-3);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
`

export const DropDown = styled.div`
  position: absolute;
  color: var(--color-text-1);
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
  padding: 8px 0;
  width: 100%;
  ${() => modalBackground}
  ${() => modalShadow}
`
