import { get, post } from 'shared/api'

import { NewSerialCutsPayload } from './type'

const getAdditionalService = async (caseId: number | string) => {
  const response = await get({
    url: `/case/${caseId}/research-request`,
  })
  return response
}

const postAdditionalService = async (caseId: number | string, data: NewSerialCutsPayload) => {
  const response = await post({
    data,
    url: `/case/${caseId}/research-request`,
  })
  return response
}

const additionalOrderService = {
  getAdditionalService,
  postAdditionalService,
}

export default additionalOrderService
