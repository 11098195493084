import { AnnotationClasses } from 'features/annotations'
import { platformAnnotationClassRgb } from 'features/annotations/ui/AnnotationClassSelect'
import { Feature } from 'ol'
import { Geometry, MultiPoint, Point } from 'ol/geom'
import RenderFeature from 'ol/render/Feature'
import { Fill, RegularShape, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { AnnotationType } from 'types/IAnnotations'
import { IMarkupClass } from 'types/IMarkupSlide'

export const defaultStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0)',
  }),
  image: new CircleStyle({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    radius: 6,
    stroke: new Stroke({
      color: '#000',
      width: 2,
    }),
  }),
  stroke: new Stroke({
    color: '#000',
    width: 2,
  }),
})

const defaultColor = 'rgba(64, 153, 247, 0)'
const defaultWidth = 2

export const defaultPlatformStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0)',
  }),
  image: new CircleStyle({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    radius: 6,
    stroke: new Stroke({
      color: defaultColor,
      width: defaultWidth,
    }),
  }),
  stroke: new Stroke({
    color: defaultColor,
    width: defaultWidth,
  }),
})

export const getDefaultTaskStyle = (taskClass?: IMarkupClass) =>
  new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0)',
    }),
    image: new CircleStyle({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      radius: 6,
      stroke: new Stroke({
        color: taskClass?.colorAsRGBA || defaultColor,
        width: taskClass?.strokeWidth || defaultWidth,
      }),
    }),
    stroke: new Stroke({
      color: taskClass?.colorAsRGBA || defaultColor,
      width: taskClass?.strokeWidth || defaultWidth,
    }),
  })

export const getCustomClass = (aClass: string, aType: AnnotationType, currTaskClasses?: IMarkupClass[]) => {
  const penTypeAdapter = aType === 'PEN' ? 'POLYGON' : aType
  return currTaskClasses?.find(
    (c) => c.name === aClass && penTypeAdapter.toLowerCase() === c.annotationType.toLowerCase(),
  )
}
export const setDefaultStyle = (
  /** Относится ли аннотация к разметке */
  isTask: boolean,
  /** Прозрачность аннотации */
  opacity: number,
  /** Объект аннотации */
  feature?: Feature<Geometry>,
  /** Классы аннотаций в текущей задаче разметки */
  currTaskClasses?: IMarkupClass[],
  /** Флаг на подсветку аннотации  */
  highlight?: boolean,
  /** Выделена ли аннотация */
  isSelect?: boolean,
) => {
  feature?.get('annotation_type') === AnnotationType.MITOSIS || feature?.get('annotation_type') === 'OBJECTS'
    ? feature.setStyle(highlight ? completedMitosisSpotStyleHover : completedMitosisSpotStyle)
    : feature?.setStyle(
        styleByAnnotationClass({
          annotationClass: feature.get('class'),
          annotationType: feature.get('annotation_type'),
          customClass: getCustomClass(feature.get('class'), feature.get('annotation_type'), currTaskClasses),
          geometry: feature.getGeometry(),
          highlight: !!highlight,
          isSelect,
          isTask,
          opacity,
        }),
      )
}

export const getCustomColor = (aColor: string) => {
  const customColor = aColor.match(/\d+/g)
  return customColor?.slice(0, 3)?.join(', ')
}

export const getArrowStyle = (
  geometry?: RenderFeature | Geometry,
  color?: string,
  displacement?: number,
  highlight?: number,
) => {
  // @ts-ignore
  if (!geometry || !geometry?.forEachSegment) return []
  const arrowStyle: Style[] = []
  // @ts-ignore
  geometry?.forEachSegment((start: number[], end: number[]) => {
    const dx = end[0] - start[0]
    const dy = end[1] - start[1]
    const rotation = Math.atan2(dy, dx)
    arrowStyle.push(
      new Style({
        geometry: new Point(end),
        image: new RegularShape({
          angle: Math.PI / 2,
          displacement: [displacement || 4, 0],
          points: 3,
          radius: 8,
          rotateWithView: true,
          rotation: -rotation,
          stroke: new Stroke({
            color: color,
            width: highlight || 2,
          }), // rotate 90°
        }),
      }),
    )
  })
  return arrowStyle
}

type StyleByAnnotationClassProps = {
  /** Аннотация в таск вьювере */
  isTask: boolean
  /** Название класса аннотации */
  annotationClass: string
  /** Стиль подсветки для аннотации */
  highlight?: boolean
  /** Нестандартный класс аннотации */
  customClass?: IMarkupClass
  /** Прозрачность аннотации */
  opacity: number
  /** Тип аннотации */
  annotationType?: AnnotationType
  /** Геометрия аннотации */
  geometry?: Geometry
  /** Выделена ли аннотация */
  isSelect?: boolean
}
export const styleByAnnotationClass = ({
  annotationClass,
  annotationType,
  customClass,
  geometry,
  highlight,
  isSelect,
  isTask,
  opacity = 0,
}: StyleByAnnotationClassProps) => {
  let aClass
  if (isTask) {
    aClass = customClass
      ? { rgb: getCustomColor(customClass.colorAsRGBA) }
      : AnnotationClasses.find((c) => c.name === annotationClass)
  } else {
    aClass = { rgb: platformAnnotationClassRgb }
  }
  const isArrow = annotationType === AnnotationType.ARROW
  const isPoint = annotationType === AnnotationType.POINT
  const customStyle = new Style({
    fill: isSelect
      ? undefined
      : new Fill({
          color: aClass ? `rgba(${aClass.rgb}, ${opacity})` : `rgba(255, 255, 255, ${opacity})`,
        }),
    image: new CircleStyle({
      fill: new Fill({
        color: aClass ? `rgba(${aClass.rgb}, 0.2)` : 'rgba(255, 255, 255, 0.2)',
      }),
      radius: 6,
      stroke: new Stroke({
        color: aClass?.rgb ? `rgba(${aClass.rgb}, 1)` : '#000',
        width: highlight ? 4 : customClass?.strokeWidth || 2,
      }),
    }),
    stroke: new Stroke({
      color: aClass?.rgb ? `rgba(${aClass.rgb}, 1)` : '#000',
      width: highlight ? 5 : customClass?.strokeWidth || 2,
    }),
  })
  return isArrow
    ? [
        ...getArrowStyle(
          geometry,
          aClass ? `rgba(${aClass.rgb})` : `rgba(255, 255, 255)`,
          undefined,
          highlight ? 5 : customClass?.strokeWidth || 2,
        ),
        customStyle,
      ]
    : isPoint && isSelect
    ? [customStyle, verticesStyle]
    : [customStyle]
}

export const dangerStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)',
  }),
  stroke: new Stroke({
    color: '#d11',
    width: 2,
  }),
})

export const verticesStyle = new Style({
  geometry: function (feature: any) {
    const type = feature.get('annotation_type') as AnnotationType
    if (type === AnnotationType.POINT) return new Point(feature.getGeometry()?.getCoordinates())
    const coordinates = feature.getGeometry()?.getCoordinates()[0]
    if (typeof coordinates[0] === 'number') {
      return new MultiPoint(feature.getGeometry()?.getCoordinates())
    }
    return new MultiPoint(coordinates)
  },
  image: new CircleStyle({
    fill: new Fill({
      color: [255, 255, 255, 1],
    }),
    radius: 5,
    stroke: new Stroke({ color: '#4099F7', width: 1 }),
  }),
})

//Ki67 style
export const negativeStyle = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: '#8CFB00',
    }),
    radius: 3,
    stroke: new Stroke({ color: '#8CFB00', width: 1 }),
  }),
  zIndex: 1000,
})

export const otherStyle = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: '#fff',
    }),
    radius: 3,
    stroke: new Stroke({ color: '#fff', width: 1 }),
  }),
  zIndex: 1000,
})

export const positiveStyle = new Style({
  image: new RegularShape({
    angle: 0,
    fill: new Fill({
      color: '#D90000',
    }),
    points: 4,
    radius: 5,
    radius2: 0,
    stroke: new Stroke({ color: '#D90000', width: 3 }),
  }),
  zIndex: 1000,
})

export const selectedStyle = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.0)',
    }),
    radius: 8,
    stroke: new Stroke({
      color: '#4099F7',
      width: 2,
    }),
  }),
  //remove fill for select annotation under selected polygon
  // fill: new Fill({
  //   color: 'rgba(0, 0, 0, 0.0)',
  // }),
  stroke: new Stroke({
    color: '#4099F7',
    width: 4,
  }),
})

export const highlightStyle = new Style({
  fill: new Fill({
    color: 'rgba(217, 0, 0, 0.1)',
  }),
  image: new CircleStyle({
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.0)',
    }),
    radius: 8,
    stroke: new Stroke({
      color: '#4e63d2',
      width: 2,
    }),
  }),
  stroke: new Stroke({
    color: 'rgba(217, 0, 0, 0.5)',
    width: 4,
  }),
})

//mitosis style
export const hardMitosisStyle = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.0)',
    }),
    radius: 15,
    stroke: new Stroke({ color: '#8CFB00', width: 3 }),
  }),
  zIndex: 1000,
})

export const softMitosisStyle = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.0)',
    }),
    radius: 15,
    stroke: new Stroke({ color: '#0569FF', width: 3 }),
  }),
  zIndex: 1000,
})

export const basePointStyle = (basePointCoordinates: number[]) =>
  new Style({
    geometry: function (feature: any) {
      return new MultiPoint([basePointCoordinates])
    },
    image: new CircleStyle({
      fill: new Fill({
        color: 'blue',
      }),
      radius: 10,
      stroke: new Stroke({ color: 'pink', width: 1 }),
    }),
  })

export const extentStyle = (extentCoordinates: number[]) =>
  new Style({
    geometry: function (feature: any) {
      return new MultiPoint(extentCoordinates)
    },
    image: new CircleStyle({
      fill: new Fill({
        color: 'pink',
      }),
      radius: 6,
      stroke: new Stroke({ color: '#f74077', width: 1 }),
    }),
  })

export const extentBlockStyle = new Style({
  stroke: new Stroke({ color: '#4099F7', width: 1 }),
})

export const defaultMitosisSpotStyle = new Style({
  stroke: new Stroke({
    color: '#0B43D1',
    lineDash: [10, 20],
    width: 2,
  }),
})

export const completedMitosisSpotStyle = new Style({
  stroke: new Stroke({
    color: '#0B43D1',
    width: 2,
  }),
})

export const completedMitosisSpotStyleHover = new Style({
  stroke: new Stroke({
    color: '#0B43D1',
    width: 5,
  }),
})

export const viewedTrackMitosisSpotStyle = new Style({
  fill: new Fill({
    color: '#ffe09352',
  }),
  stroke: new Stroke({
    color: '#FFE193',
    width: 3,
  }),
})

export const mitosisPointStyle = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: 'rgba(27, 100, 27, 0)',
    }),
    radius: 30,
    stroke: new Stroke({
      color: '#1B641B',
      width: 3,
    }),
  }),
})

export const objectsPointStyle = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: 'rgba(27, 100, 27, 0)',
    }),
    radius: 10,
    stroke: new Stroke({
      color: '#1B641B',
      width: 2,
    }),
  }),
})

export const styleMitosisByFeatureElement = (feature: Feature<any>) => {
  const element = feature.get('element')
  const viewed = feature.get('viewed')
  switch (element) {
    case 'bbox':
      return viewed ? completedMitosisSpotStyle : defaultMitosisSpotStyle
    case 'track':
      return viewedTrackMitosisSpotStyle
    default:
      return defaultStyle
  }
}
