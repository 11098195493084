import { useTypedSelector } from 'app/redux/lib/selector'
import { push, replace } from 'connected-react-router'
import { useCaseQuery } from 'features/cases/api/query'
import { useSlideGrouped } from 'features/thumbnails/hooks'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { selectUrlCaseId, selectUrlSlideId, viewerPageSlice } from 'pages/viewer'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'

export const useViewerRoute = () => {
  const dispatch = useDispatch()
  const urlCaseId = useSelector(selectUrlCaseId)
  const urlSlideId = useSelector(selectUrlSlideId)
  const { activeViewerId: viewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { firstViewerId } = useOpenViewers()
  const { changeViewerSlide } = useViewerPageProvided()
  const routerState = useTypedSelector((state) => state.router.location.state)
  const workspaceId = useCurrentWorkspaceId()
  const { source } = useViewerIdSlideState(viewerId)
  const { data: caseRecord, isError, isLoading: isCaseLoading } = useCaseQuery({ caseId: urlCaseId, source })
  const { selectedBbox } = useViewerMainSelector(firstViewerId)
  const { slideGrouped, slides, slidesIds } = useSlideGrouped(urlCaseId || 0)
  const isCorrectSlide = !!slides.find((s) => s.slideId === urlSlideId)
  const isNoSlideId = !urlSlideId

  /**Select first slide if no/wrong slideId url param */
  useEffect(() => {
    if (caseRecord && (isNoSlideId || !isCorrectSlide)) {
      const firstSlideId = slideGrouped?.[0]?.slides[0]?.slideId || 0
      const firstSlideData = slideGrouped?.[0]?.slides[0]
      const correctSlideId = slidesIds.find((slideId) => slideId === firstSlideId)
      const firstGroupType = firstSlideData?.groupType
      if (!correctSlideId || !firstGroupType) return

      dispatch(replace(`/workspace/${workspaceId}/viewer/${caseRecord.caseId}?slideId=${firstSlideId}`, routerState))

      changeViewerSlide(firstViewerId, {
        caseId: caseRecord.caseId,
        slideGroupType: firstGroupType,
        slideId: firstSlideId,
        source: caseRecord.source,
        viewerMode: 'DEFAULT',
      })
    }
  }, [slideGrouped, caseRecord, isNoSlideId, urlCaseId, isCorrectSlide, workspaceId])

  const goToCasesList = useCallback(() => dispatch(push(`/workspace/${workspaceId}/cases?rel=owner`)), [])
  const goToCase = useCallback(
    () => dispatch(push(`/workspace/${workspaceId}/cases?rel=shared&caseId=${urlCaseId}`)),
    [],
  )

  const isWrongCase = isError || !caseRecord
  const isNoSlide = false

  useEffect(
    () => () => {
      /** Clear selected bbox*/
      !!selectedBbox && viewerDispatch(viewerSlice.actions.setSelectedBbox())
      dispatch(viewerPageSlice.actions.resetAllViewersState())
    },
    [],
  )

  return { goToCase, goToCasesList, isCaseLoading, isNoSlide, isWrongCase }
}
