/** Тип таблицы кейсов */
export enum ECaseTableType {
  /** Распределение */
  ROUTING = 'routing',
  /** Мои случаи (Поменяли местами названия табов 1 и 2 уровня, эндпоинт остался прежним, поэтому worklist) */
  WORKLIST = 'worklist',
  /** Все случаи */
  WORKSPACE = 'workspace',
}

/** Тип 2-го уровня табов в распределение */
export enum EDistributionTabType {
  /** Не распределены */
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  /** Отклонены */
  DECLINED = 'DECLINED',
  /** Все без заключения */
  NOT_COMPLETED = 'NOT_COMPLETED',
  /** Распределены */
  ASSIGNED = 'ASSIGNED',
}

/** Тип 2-го уровня табов в моих случаях */
export enum EMyCasesTabType {
  /** Рабочий список */
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
  /** Входящие */
  INCOMING = 'INCOMING',
  /** Отслеживаемые случаи */
  FAVORITES = 'FAVORITES',
  /** Дозаказы */
  ASSIGNED_TO_ME_WITH_RESEARCH_REQUESTS = 'ASSIGNED_TO_ME_WITH_RESEARCH_REQUESTS',
}

/** Тип 2-го уровня табов во всех случаях */
export enum EAllCasesTabType {
  /** Все статусы*/
  ALL_STATUSES = 'ALL_STATUSES',
  /** Открытые */
  OPENED = 'OPENED',
  /** Завершённые/подписанные */
  COMPLETED = 'COMPLETED',
  /** Удалённые случаи (корзина) */
  DELETED = 'DELETED',
}

/** Тип 3-й уровня вкладки в моих случаях -> отслеживаемые */
export enum EFavoriteList {
  /** Отслеживаемые */
  WATCHING = 'WATCHING',
  /** Избранные */
  FAVORITES = 'FAVORITES',
}

/** Тип для курсора, когда находимся на 1 странице или нет выделнного случая */
export enum ECaseCursor {
  NULL = 'NULL',
}

/** Роль кейса для юзера */
export enum ECaseRelation {
  SHARED = 'SHARED',
}
