import { TFunction } from 'i18next'
import ISlide from 'types/ISlide'

export function getSlideText(t: TFunction<'translation', undefined>, slide?: ISlide): string {
  if (slide?.groupType === 'MACRO') {
    return t('Макро')
  }
  const blockText = slide?.block
  const containerText = slide?.container

  if (containerText && blockText) {
    return `${containerText}-${blockText}`
  }

  if (containerText) {
    return containerText
  }

  if (blockText) {
    return blockText
  }

  return t('Нет блока')
}
