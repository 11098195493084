import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { createGlobalStyle } from 'styled-components/macro'

export const GlobalAntNotificationStyle = createGlobalStyle`
    .ant-notification {
        z-index: 12;
    }
  .ant-notification-topRight, .ant-notification-bottomLeft {
    position: absolute;
  }
  .ant-notification-hook-holder > .ant-notification-notice {
    padding: 4px;
  }
  .ant-notification-notice-message {
    margin-bottom: 0;
    color: var(--color-text-1);
  }
  .ant-notification-hook-holder, .ant-notification-notice {
    width: 258px;
  }
  .ant-notification-notice {
    padding: 8px;
    ${() => modalBackground}
    ${() => modalShadow}
  }
  .ant-notification-notice-close {
    top: 4px;
    right: 4px;
    color: var(--color-text-3);
  }
`
