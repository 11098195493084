import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { STATUS_ADDITIONAL_ORDER } from 'features/additional-order'
import styled from 'styled-components'

import { ButtonProps, SpanProps, StatusProps } from './AdditionalOrderInfo.types'

export const StyledPanel = styled.div`
  position: absolute;
  overflow: auto;
  max-height: 80%;
  top: 130px;
  right: calc(100% + 8px);
  padding: 0 8px 8px 8px;
  width: 268px;
  border-radius: 5px;
  z-index: 100;
  ${() => modalBackground}
  ${() => modalShadow}
`

export const Status = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  background-color: ${({ status }) => {
    switch (status) {
      case STATUS_ADDITIONAL_ORDER.CREATION_PENDING:
        return 'var(--color-blue)'
      case STATUS_ADDITIONAL_ORDER.CREATED:
        return 'var(--color-blue)'
      case STATUS_ADDITIONAL_ORDER.IN_PROGRESS:
        return 'var(--color-green)'
      case STATUS_ADDITIONAL_ORDER.READY:
      case STATUS_ADDITIONAL_ORDER.CANCELLED:
        return 'var(--color-bg-4)'
      case STATUS_ADDITIONAL_ORDER.FAILED:
        return 'var(--color-red)'
      default:
        return 'transparent'
    }
  }};
  width: max-content;
  height: 18px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 600;
  color: ${({ theme }) => (theme === 'dark' ? 'var(--color-black)' : 'var(--color-white)')};
`

export const Item = styled.div`
  &:not(:last-child) {
    padding: 8px 0px;
    border-bottom: 1px solid var(--color-border-1);
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitleSpan = styled.span`
  font-size: 14px;
  color: var(--color-text-1);
`

export const Span = styled.span<SpanProps>`
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${(props) => (props.color === 'white' ? 'var(--color-text-1)' : 'var(--color-text-3)')};
  word-wrap: break-word;
  white-space: normal;
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const Button = styled.button<ButtonProps>`
  margin-bottom: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-bg-3);
  width: 100%;
  height: 32px;
  border-width: 1px;
  border-color: ${(props) => (props.isFocus ? 'var(--color-purple)' : 'transparent')};
  border-style: solid;
  font-size: 13px;
  color: var(--color-text-1);
  font-weight: 500;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  cursor: pointer;
`

export const ShowMoreButton = styled.button`
  background: none;
  color: var(--color-blue);
  border: none;
  cursor: pointer;
  font-size: 12px;
  display: inline;

  &:hover {
    text-decoration: underline;
  }
`

export const Mark = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-left: 4px;
  padding-right: 4px;
  height: 16px;
  min-width: 16px;
  background-color: var(--color-purple);
  color: var(--color-white);
  font-size: 11px;
  line-height: 12px;
  font-weight: 600;
`

export const Dot = styled.span`
  &:before {
    content: ' • ';
  }
`
