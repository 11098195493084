import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { ButtonElement, IconElement, TextAreaElement } from 'shared/ui/kit'

import { ButtonElems, ErrorMessage, Label, LabelTitle, StyledModal, Wrapper } from './ModalWrapper.styles'
import { IModalWrapperProps } from './ModalWrapper.types'

/** Оболочка для модалок по дозаказам  */
export const ModalWrapper = ({
  children,
  error,
  handleComment,
  handleSubmit,
  isDisabled,
  isError,
  onClose,
  refModal,
  title,
  valueComment,
}: IModalWrapperProps) => {
  const { t } = useTranslation()

  return (
    <StyledModal
      title={title}
      ref={refModal}
      centered
      footer={null}
      destroyOnClose
      closeIcon={<IconElement name="cross16" size="md" onClick={onClose} />}
    >
      <Wrapper>
        {children}
        <Divider />
        <Label>
          <LabelTitle>{t('Комментарий для лаборанта')}</LabelTitle>
          <TextAreaElement
            autoSize={{ maxRows: 10, minRows: 3 }}
            maxLength={300}
            value={valueComment}
            onChange={handleComment}
          />
        </Label>
        {isError && <ErrorMessage>{error?.message}</ErrorMessage>}
        <Divider />

        <ButtonElems>
          <ButtonElement onClick={onClose}>{t('Отменить')}</ButtonElement>
          <ButtonElement disabled={isDisabled} type="primary" onClick={handleSubmit}>
            {t('Отправить')}
          </ButtonElement>
        </ButtonElems>
      </Wrapper>
    </StyledModal>
  )
}
