import { useTypedSelector } from 'app/redux/lib/selector'
import { SearchContainer, SimilarSlidesProvider } from 'features/search'
import { ViewerLeftPanelType } from 'pages/viewer/model/viewerPageSlice'
import React from 'react'
import styled from 'styled-components'

type Props = {
  leftRef: React.ForwardedRef<HTMLDivElement>
  children: any
  style?: any
}

const Wrapper = styled.div`
  overflow-y: hidden;
  height: 100%;
`

const StyledLeftPanel = styled.div<{ leftPanelType: ViewerLeftPanelType }>`
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-tabs-top {
    height: 100%;
  }

  .ant-tabs-content-top {
    height: 100%;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    padding-bottom: 16px;
    margin: 0;
    border-bottom: 1px solid var(--color-border-1);
  }

  .ant-tabs-tab {
    padding: 0;
    margin: 0;
  }
`

const LeftPanelWrapper = ({ children, leftRef, style }: Props) => {
  const leftPanelType = useTypedSelector((state) => state.viewerPage.leftPanelType)

  return (
    <Wrapper ref={leftRef}>
      <StyledLeftPanel leftPanelType={leftPanelType} style={style}>
        <SimilarSlidesProvider>{leftPanelType === 'SLIDES' ? children : <SearchContainer />}</SimilarSlidesProvider>
      </StyledLeftPanel>
    </Wrapper>
  )
}

export default LeftPanelWrapper
