import { DictionaryItem } from 'types/IDictionary'
import { IGroupType } from 'types/ISlide'
import { ISlideMetadata } from 'types/ISlideMetadata'

type ConvertType = {
  slideExternalId: string
  stain: DictionaryItem
  caseId: number
}

type ISlideState = 'PROCESSING' | 'FAILED' | 'AVAILABLE'

export const convertToISlide = ({ caseId, slideExternalId, stain }: ConvertType) => {
  const slideExternal = slideExternalId.replace(/\D/g, '')
  const numberSlideExternalId = Number(slideExternal)
  return {
    caseId: caseId,
    deleted: false,
    deletedAt: null,
    groupType: 'MICRO' as IGroupType,
    iiif2Url: '',
    name: slideExternalId,
    slideDefect: null,
    slideId: numberSlideExternalId,
    slideMetadata: {
      commonMetadata: {
        caption: slideExternalId,
        segmentationStatus: 'FAILED',
      },
    } as ISlideMetadata,
    stain: stain,
    state: 'FAILED' as ISlideState,
    thumbnails: { large: 'FAILED_SLIDE', medium: 'FAILED_SLIDE', small: 'FAILED_SLIDE' },
    viewed: null,
  }
}
